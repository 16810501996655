import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import closeIcon from '../assets/close.png';
import arrowrightIcon from '../assets/arrow-right.png';
import { useWallet } from '../WalletContext';
import { registerUserDomain } from '../contracts/contract.ts';

const ConfirmTransaction = () => {
    const [seconds, setSeconds] = useState(300);
    const navigate = useNavigate();
    const location = useLocation();
    const { walletAddress } = useWallet();

    const domain = new URLSearchParams(location.search).get('domain');
    const years = new URLSearchParams(location.search).get('years');

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds(prev => prev - 1);
        }, 1000);

        if (seconds === 0) {
            clearInterval(timer);
            navigate(`/confirmation`);
        }

        return () => clearInterval(timer);
    }, [seconds, navigate]);

    const handleOpenWallet = async () => {
        if (!walletAddress) {
            alert('Please connect your wallet first!');
            return;
        }
        const regLink = await registerUserDomain(domain, years);
        console.log(regLink);
        if (regLink) {
            navigate(`/confirm?domain=${domain}&years=${years}&regLink=${encodeURIComponent(regLink)}`);
        } else {
            alert('Error in registering domain');
        }
    };

    return (
        <div className="bg-[#0C0C0C] min-h-screen flex flex-col justify-between">
            <Navbar />
            <div className="flex flex-col items-center mt-8 justify-center h-screen font-manrope">
                <div className="w-[671px] h-[547.72px] p-[25.08px_0_0_0] gap-[25.08px] rounded-xl border border-[#292D32] bg-[#292D32] shadow-[0px_21px_47px_#0000000D, 0px_85px_85px_#0000000A, 0px_191px_115px_#00000008, 0px_339px_136px_#00000003, 0px_530px_149px_#00000000]">
                    <h1 className="font-manrope text-[25px] font-[600] leading-[71.05px] text-left px-16 mb-2 mt-[-20px] text-white flex justify-between items-center">
                        <span>Confirm Details for {domain}</span>
                        <img src={closeIcon} alt="close" className="cursor-pointer" />
                    </h1>
                    <hr className="border-t-2 border-white ml-10 mb-6 justify-center w-[580.84px]" />
                    <p className="text-sm mb-6 ml-[-160px] text-gray-400">Double check these details before confirming in your wallet.</p>

                    <div className="w-[540.84px] h-[86.19px] mb-4 p-4 ml-14 rounded-lg border border-[#A8A4A5] bg-[#292D32] flex justify-between items-center">
                        <p className="text-xl text-white">Name</p>
                        <p className="text-xl text-white">{domain}.eth</p>
                    </div>

                    <div className="w-[540.84px] h-[86.19px] mb-4 p-4 ml-14 rounded-lg border border-[#A8A4A5] bg-[#292D32] flex justify-between items-center">
                        <p className="text-xl text-white">Action</p>
                        <p className="text-xl text-white">Start Timer</p>
                    </div>

                    <div className="w-[540.84px] h-[86.19px] ml-14 rounded-lg border border-[#A8A4A5] bg-[#292D32] mb-4 p-4 flex flex-col justify-between">
                        <p className="text-xl text-white">Transaction will complete in</p>
                        <p className="text-xl text-white">{seconds} seconds</p>
                    </div>

                    <button
                        onClick={handleOpenWallet}
                        className="w-[540.84px] h-[66.19px] ml-[-15px] bg-[#0EAD98] border border-[#0000001A] shadow-[0px_3.67px_0px_#0000000B] rounded-lg text-black font-manrope font-bold text-lg">
                        <span className="text-base flex justify-center text-black text-xl font-manrope font-bold">
                            Open Wallet
                            <img src={arrowrightIcon} alt="arrow-right" className="ml-[20px]" />
                        </span>
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ConfirmTransaction;

import { Link } from 'react-router-dom';
import { connectWallet } from '../contracts/contract.ts';
import React from 'react';
import { useWallet } from '../WalletContext'; // Import useWallet

const Navbar = () => {
  const { walletAddress, setWalletAddress } = useWallet(); // Destructure from context
  const [errorMessage, setErrorMessage] = React.useState(""); // To store any error messages

  const handleConnectWallet = async () => {
    try {
      const signer = await connectWallet(); // Call the connectWallet function
      if (signer) {
        const address = await signer.getAddress(); // Get the connected wallet address
        setWalletAddress(address); // Set the wallet address in context
        setErrorMessage(""); // Clear any previous error messages
        console.log("Connected Wallet Address:", address);
      }
    } catch (error) {
      setErrorMessage("Failed to connect wallet."); // Set an error message if connection fails
    }
  };

  return (
    <header className="flex justify-between items-center w-full px-6 py-4 bg-[#0C0C0C] fixed top-0 z-10">
      {/* Logo */}
      <div className="text-[#0EAD98] text-2xl font-semibold">CoinEx ID</div>

      {/* Navbar Links */}
      <nav className="space-x-8">
        <Link to="/" className="text-[#0EAD98] hover:underline font-manrope text-[20px] font-normal leading-[29px] tracking-[-0.63px]">Home</Link>
        <Link to="/view-name" className="text-white hover:underline font-manrope text-[20px] font-normal leading-[29px] tracking-[-0.63px]">My Names</Link>
        <Link to="/docs" className="text-white hover:underline font-manrope text-[20px] font-normal leading-[29px] tracking-[-0.63px]">Docs</Link>
      </nav>

      {/* Connect Wallet Button */}
      <button
        className="bg-[#0EAD98] px-4 py-2 rounded font-manrope text-[20px] font-normal leading-[29px] tracking-[-0.63px]"
        onClick={handleConnectWallet} // Call the function on click
      >
        {walletAddress ? `Connected: ${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}` : "Connect Wallet"}
      </button>

      {/* Display error message if any */}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </header>
  );
};

export default Navbar;

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import RegisterDomain from './pages/RegisterDomain';
import ConfirmTransaction from './pages/ConfirmTransaction';
import Confirm from './pages/Confirm';
import Mynames from './pages/Mynames';
import Profilepage from './pages/Profilepage';
import { WalletProvider } from './WalletContext';
import './App.css';

function App() {
  return (
    <div className="App">
      <WalletProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register-domain" element={<RegisterDomain />} /> {/* Updated this line */}
          <Route path="/confirm-transaction" element={<ConfirmTransaction />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/view-name" element={<Mynames />} />
          <Route path="/Profilepage" element={<Profilepage />} />
        </Routes>
      </Router>
      </WalletProvider> 
    </div>
  );
}

export default App;

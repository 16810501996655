import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-[#0C0C0C] w-full py-1">
            <div className="container mx-auto px-1 text-right">
                <p className="text-white font-manrope text-[12px] font-normal leading-[24.18px] opacity-100">
                    © 2024 CoinEX. ALL RIGHTS RESERVED.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
    import React, { useState } from 'react';
    import Navbar from '../components/Navbar';
    import Footer from '../components/Footer'; 
    import { useNavigate } from 'react-router-dom';
    import arrowIcon from '../assets/arrow.png';
    import profileIcon from '../assets/profile.png';
    import twitterIcon from '../assets/twitter.png';
    import mailcon from '../assets/mail.png';
    import telegramIcon from '../assets/telegram.png';
    import starIcon from '../assets/star.png';
    import arrowlineIcon from '../assets/arrow-line.png';
    import image1 from '../assets/image1.png';
    import image2 from '../assets/image2.png';
    import image3 from '../assets/image3.png'; 
    import image4 from '../assets/image4.png';


    const Home = () => {  
        const navigate = useNavigate();  
        const [domain, setDomain] = useState(''); 

        const handleClaimNowClick = () => {
            if (domain.trim()) { // Ensure there's a domain entered
                navigate(`/register-domain?domain=${encodeURIComponent(domain)}`); // Navigate to RegisterDomain page with domain
            } else {
                alert('Please enter a valid domain name.'); // Optional: Alert if the input is empty
            }
        };
        
        return (
            <div className="bg-[#0C0C0C] min-h-screen flex flex-col justify-between">
                <Navbar />

                {/* Left Section */}
                <div className="flex flex-col justify-center items-start mt-[30px] p-8 w-1/2 ">
                    <h1 className="font-manrope text-[60px] font-bold leading-[70px] tracking-[-0.626984] text-white mb-0 py-6">
                        Decentralize your digital </h1>
                    <h1 className="font-manrope text-[60px] font-bold leading-[70px] tracking-[-0.626984] text-white mb-0">
                        identity with one click.
                    </h1>
                    <h2 className="font-manrope text-[25px] font-light leading-[36.25px] tracking-[-0.626984] text-white mb-0 py-8">
                        Efficient, seamless, easy way to decentralize your identity
                    </h2>

                    {/* Container for input and button */}
                    <div className="relative mt-4 w-[586px]">
                        <input
                            type="text"
                            placeholder="Enter Your Domain"
                            value={domain} 
                            onChange={(e) => setDomain(e.target.value)}
                            className="font-manrope w-full h-[76px] px-4 py-2 text-white font-bold rounded-lg border border-t-0 border-l-0 border-r-0 border-[#292D32] bg-[#292D32] opacity-100 focus:outline-none"
                        />
                        <button
                            onClick={handleClaimNowClick} 
                            className="absolute right-3 top-3 w-[148px] h-[56px] bg-[#0EAD98] text-white font-manrope font-bold rounded-lg hover:bg-opacity-80 transition duration-200">
                            Claim Now
                        </button>
                    </div>

                    <div className="flex mt-2 space-x-8">
                    <div className="mt-4 mb-[-240px] w-[381px] h-[250px] p-[50px_0_0_0] rounded-[10px_0_0_0] border border-[#292D32] bg-[#1A1A1A] opacity-100">
                        <div className="w-[381px] h-[174px] px-6 py-2">
                            <div className="flex mb-2">
                                <img src={starIcon} alt="Star" className="w-5 h-5 mr-2 mt-[-40px] mb-[-50px]" /> {/* Replace with actual path */}
                                <span className="font-manrope mt-[-40px] text-[15px] font-light text-[#0EAD98]">
                                    About CoinEx ID
                                </span>
                            </div>
                            <p className="font-manrope text-[15px] text-white mb-1 py-[-40px]">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pulvinar nulla at dui convallis, sed elementum lectus sodales. Nulla facilisi. Vestibulum euismod mi in arcu efficitur tincidunt. Proin sit amet nisl lectus. Nullam imperdiet vehicula tortor non ferment.
                            </p>
                            <div className="flex space-x-4">
                                <img src={twitterIcon} alt="Twitter" className="w-8 h-8 rounded-full" />
                                <img src={mailcon} alt="Mail" className="w-8 h-8 rounded-full" />
                                <img src={telegramIcon} alt="Telegram" className="w-8 h-8 rounded-full" />
                            </div>
                        </div>
                    </div>

                        <div className="mt-4 mb-[-240px] w-[381px] h-[260px] rounded-[10px_0_0_0] border border-[#292D32] bg-[#292D32] p-4">
                            <div className="flex items-center mb-4">
                                <img src={starIcon} alt="Star" className="w-5 h-5 mr-2" />
                                <span className="font-manrope text-[15px] font-light text-[#0EAD98]">
                                    CoinEX Ecosystem
                                </span>
                            </div>

                            <div className="flex flex-col space-y-4">
                                <div className="flex items-center">
                                    <img src={image1} alt="Description of Image 1" className="w-[229px] h-[30px]" />
                                    <img src={arrowlineIcon} alt="Twitter Icon" className="w-6 h-6 ml-2" />
                                </div>
                                <div className="flex items-center">
                                    <img src={image2} alt="Description of Image 2" className="w-[259px] h-[35px]" />
                                    <img src={arrowlineIcon} alt="Twitter Icon" className="w-6 h-6 ml-2" />
                                </div>
                                <div className="flex items-center">
                                    <img src={image3} alt="Description of Image 3" className="w-[229px] h-[35px]" />
                                    <img src={arrowlineIcon} alt="Twitter Icon" className="w-6 h-6 ml-2" />
                                </div>
                                <div className="flex items-center">
                                    <img src={image4} alt="Description of Image 4" className="w-[229px] h-[35px]" />
                                    <img src={arrowlineIcon} alt="Twitter Icon" className="w-6 h-6 ml-2" />
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>

                {/* Right Section */}
                <div className="w-1/2 flex flex-col items-start justify-start p-8 mt-[-1080px]">
                    {/* Container for the overlapping boxes */}
                    <div className="absolute mt-12 left-[62.5%]"> {/* Adjusted to left-0 to align with the start of the right section */}
                        {/* First Box for sham.cet */}
                        <div className="relative mb-[-40px]"> {/* Negative margin for overlap */}
                            <div className="w-[192px] h-[111px] p-4 border border-[#0EAD9880] rounded-[10px_0_0_0] flex flex-col items-start justify-center bg-transparent">
                                <span className="font-manrope mb-[10%] mt-2 ml-[20px] text-[25px] font-light text-white">
                                    sham.cet
                                </span>
                                <img
                                    src={profileIcon} 
                                    alt="Profile"
                                    className="absolute left-2 top-2 w-[24px] h-[24px]"
                                />
                                <div className="mt-6 ml-[15px] my-[-20%] mx-[-20%] mb-4 w-[76px] h-[29px] bg-[#0EAD98] flex  justify-center rounded-[2px_0_0_0]">
                                    <span className="font-manrope text-[20px] font-medium text-black">
                                        Sends
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="absolute top-0 left-0 w-[192px] h-[111px] rounded-[10px_0_0_0] bg-[#0C0C0C] opacity-0" /> {/* Overlapping box */}
        

                        {/* Arrow */}
                        <img
                            src={arrowIcon}
                            alt="Arrow"
                            className="absolute top-[125px] left-[50px] w-[89px] h-[39.68px]" // Adjust width and height as needed
                        />


                        {/* Second Box that overlaps the first one */}
                        <div className="relative">
                            <div className="absolute top-0 left-4 transform -translate-y-[-1%] -translate-x-[-70%]"> {/* Adjusted left property */}
                                <div className="w-[192px] h-[111px] p-4 border border-[#0EAD9880] rounded-[10px_0_0_0] flex flex-col items-start justify-center bg-transparent">
                                    <span className="font-manrope mb-[10%] mt-2 ml-[20px] text-[25px] font-light text-white">
                                        ram.cet
                                    </span>
                                    <img
                                        src={profileIcon}
                                        alt="Profile"
                                        className="absolute left-2 top-2 w-[24px] h-[24px]"
                                    />
                                    <div className="mt-6 ml-[15px] my-[-20%] mx-[-20%] mb-4 w-[85px] h-[29px] bg-[#0EAD98] flex  justify-center rounded-[2px_0_0_0]">
                                        <span className="font-manrope text-[20px] font-medium text-black">
                                            Receives
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Box for Send */}
                    <div className="absolute right-5 mt-12 ">
                        <div className="w-[308px] h-[49px] bg-[#0EAD98] flex items-center justify-center rounded-lg">
                            <span className="font-manrope text-[18px] font-light text-black">
                                Send CET Tokens to anyone easily
                            </span>
                        </div>
                    </div>
                </div>
                
                <Footer />
            </div>
            
        );
    };

    export default Home;

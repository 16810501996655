import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import squareIcon from '../assets/square.png';
import pinIcon from '../assets/pin.png';

const ProfilePage = () => {
    const [activeTab, setActiveTab] = useState('Profile');

    const tabs = ['Profile', 'Ownership', 'Surnames', 'Permissions', 'More'];

    return (
        <div className="min-h-screen flex flex-col bg-[#0C0C0C] text-white">
            {/* Navbar */}
            <Navbar />

            {/* Main Content */}
            <div className="flex-grow flex flex-col items-center">
                {/* Profile Box */}
                <div className="w-[800px] bg-[#0C0C0C] font-manrope mt-40 h-auto rounded-xl p-6 shadow-lg flex flex-col items-center">
                    {/* Image and Profile Pic */}
                    <div className="w-full flex justify-between items-center mb-6">
                        <div className="flex items-center">
                            <div className="ml-1 text-2xl font-bold">pranavvin.eth</div>
                            <img
                                src={squareIcon}
                                alt="square"
                                className="w-4 h-4 ml-2"
                            />
                        </div>
                        <div className="flex items-center">
                            <img
                                src={pinIcon}
                                alt="pin"
                                className="w-5 h-5 ml-2"
                            />
                            <span className="text-[#0EAD98] ml-2 text-xl">Etherscan</span>
                        </div>
                    </div>

                    {/* Tabs */}
                    <div className="flex space-x-6 mt-6">
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                className={`px-4 py-2 text-lg ${activeTab === tab ? 'text-[#0EAD98] border-b-2 border-[#0EAD98]' : 'text-gray-400'
                                    }`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    {/* Primary name box */}
                    <div className="w-[800px] bg-[#0C0C0C] font-manrope mt-20 h-auto rounded-xl p-6 shadow-lg flex flex-col items-center">
                        {/* Two-column layout */}
                        <div className="flex w-full h-full">
                            {/* Left Column (Profile Picture and Name) */}
                            <div className="w-1/2 flex flex-col items-center justify-center">
                                {/* Profile Picture (Circle) */}
                                <div className="w-32 h-32 bg-gray-400 rounded-full flex items-center justify-center">
                                    {/* Placeholder for Profile Pic */}
                                    <img src={squareIcon} alt="Profile Pic" className="w-28 h-28 rounded-full object-cover" />
                                </div>

                                {/* Name */}
                                <div className="mt-4 text-2xl font-bold">pravvi.nn.eth</div>

                                {/* Button */}
                                <button className="mt-4 w-[126.81px] h-[25.38px] text-sm bg-[#0EAD98] rounded-full opacity-100">
                                    Extend
                                </button>
                            </div>

                            {/* Right Column (Placeholder for more content) */}
                            <div className="w-1/2 flex items-center justify-center">
                                {/* This is where you can add more content in the right half */}
                                <div className="text-gray-400">
                                    Additional information or buttons can go here.
                                </div>
                            </div>
                        </div>

                    {/* Address and Ownership Section */}
                    <div className="mt-6 w-full space-y-4">
                        <div className="p-4 border border-gray-700 rounded flex justify-between items-center">
                            <span className="text-sm text-gray-400">Address: 0x5d...xCwE</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-[#0EAD98]"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M10 3a1 1 0 011 1v10a1 1 0 11-2 0V4a1 1 0 011-1z" />
                            </svg>
                        </div>

                        <div className="p-4 border border-gray-700 rounded flex justify-between items-center">
                            <span className="text-sm text-gray-400">Ownership: View</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-[#0EAD98]"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 9.293a1 1 0 011.414 0L15 10.586l1.293-1.293a1 1 0 011.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>

                        {/* More sections */}
                        <div className="p-4 border border-gray-700 rounded">More content here...</div>
                    </div>
                </div>
            </div>
        </div>  
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default ProfilePage;

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Confirm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const domain = new URLSearchParams(location.search).get('domain');
    const years = parseInt(new URLSearchParams(location.search).get('years'), 10);
    const regLink = new URLSearchParams(location.search).get('regLink');

    const priceMapping = {
        1: 0.05,
        2: 0.095,
        3: 0.135,
        4: 0.17,
        5: 0.2,
    };

    const totalRegistrationFee = priceMapping[years];
    const estimatedNetworkFee = totalRegistrationFee;
    const estimatedTotal = totalRegistrationFee;

    const handleOpenWallet = () => {
        navigate(`/confirm-transaction?domain=${domain}&years=${years}`);
    };

    return (
        <div className="bg-[#0C0C0C] min-h-screen flex flex-col">
            <Navbar />
            <div className="flex flex-col items-center mt-8 justify-center h-screen font-manrope relative">
                <div className="w-[800px] h-[650px] p-8 rounded-xl border border-[#292D32] bg-[#292D32] shadow-lg">
                    {/* Inner Box */}
                    <div className="w-[700px] h-[450px] p-8 bg-[#0EAD98] rounded-lg mx-auto">
                        {/* Top Bar */}
                        <div className="flex justify-between items-center mb-6">
                            <span className="text-black text-[25px] font-bold">CoinEX ID</span>
                            <span className="text-black text-[25px] font-bold">{domain}</span>
                        </div>

                        {/* Congratulations Message */}
                        <div className="mt-16 mb-12 text-left">
                            <h2 className="text-black font-bold text-[50px] leading-tight mb-6">
                                Congratulations!
                            </h2>
                            <p className="text-black font-bold text-[25px] mb-4">
                                You are now the owner of <strong>{domain}</strong>
                            </p>
                            <p className="text-black text-[20px] mb-2">
                                Your name was successfully registered. You can now view and manage Your Name.
                                Transaction Link : <a href={regLink} target="_blank" rel="noreferrer">{regLink}</a>
                            </p>
                        </div>
                    </div>
                </div>

                {/* Overlapping Bottom Box */}
                <div className="w-[640px] h-[250px] p-6 bg-[#292D32] border border-[#292D32] rounded-lg shadow-lg flex flex-col absolute bottom-[60px] z-20">
                    <div className="flex justify-between mb-4">
                        <div className="text-white text-left">
                            <p className="text-gray-400">{years} year{years > 1 ? 's' : ''} registration</p>
                            <p className="text-gray-400">Est. network fee</p><br />
                            <p className="text-[#0EAD98]">Estimated total</p>
                        </div>
                        <div className="text-gray-400 text-right">
                            <p>{totalRegistrationFee.toFixed(4)} CET</p>
                            <p>{estimatedNetworkFee.toFixed(4)} CET</p> <br />
                            <p className="font-bold text-[#0EAD98]">{estimatedTotal.toFixed(4)} CET</p> </div>
                    </div>

                    {/* Horizontal Line */}
                    <hr className="border-t border-[#0EAD98] w-full mb-6" />

                    {/* Buttons (placed below the HR line) */}
                    <div className="flex gap-4 justify-center">
                        {/* Register another button (outlined, transparent inside) */}
                        <button
                            className="w-[199.97px] h-[63.68px] border border-[#0EAD98] text-[#0EAD98] rounded-[3.82px_0px_0px_0px] px-[25.49px] py-[17.84px] flex items-center justify-center"
                            style={{ backgroundColor: 'transparent' }}
                            onClick={() => navigate('/register-another')}
                        >
                            Register another
                        </button>

                        {/* View name button (solid teal background) */}
                        <button
                            className="w-[199.97px] h-[63.68px] bg-[#0EAD98] text-white rounded-[3.82px_0px_0px_0px] px-[25.49px] py-[17.84px] flex items-center justify-center"
                            onClick={() => navigate(`/view-name?domain=${domain}`)}
                        >
                            View name
                        </button>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Confirm;
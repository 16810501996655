import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import minusIcon from '../assets/minus.png';
import plusIcon from '../assets/plus.png'; 
import gweiIcon from '../assets/gwei.png';
import arrowrightIcon from '../assets/arrow-right.png';

const RegisterDomain = () => {
    const [years, setYears] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const domain = new URLSearchParams(location.search).get('domain');

    const priceMapping = {
        1: 0.05,
        2: 0.095,
        3: 0.135,
        4: 0.17,
        5: 0.2,
    };


    const handlePayClick = () => {
        if (domain) {  // Check if domain exists
            navigate(`/confirm-transaction?domain=${domain}&years=${years}`);
        } else {
            alert("No domain provided"); // Optional: Alert if the domain is invalid
        }
    };


    return (
        <div className="bg-[#0C0C0C] min-h-screen flex flex-col justify-between">
            <Navbar />

            {/* Center Box */}
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="w-[671px] h-[527.72px] p-[25.08px_0_0_0] gap-[25.08px] rounded-xl border border-[#292D32] bg-[#292D32] shadow-[0px_21px_47px_#0000000D, 0px_85px_85px_#0000000A, 0px_191px_115px_#00000008, 0px_339px_136px_#00000003, 0px_530px_149px_#00000000]">
                    {/* Domain Registration Header */}
                    {domain ? (
                        <h1 className="font-manrope text-[39px] font-[600] leading-[71.05px] text-left px-4 mb-6 text-white">
                            Register {domain}.cet
                        </h1>
                    ) : (
                        <h1 className="text-red-500">No domain provided!</h1>
                    )}

                    {/* Years Selection Box */}
                    <div className="flex justify-between items-center ml-4 w-[640.84px] h-[85.3px] rounded-[0px_0px_0px_0px] border border-[#A8A4A5] bg-[#292D32] mb-4">
                        <button
                            onClick={() => setYears(years - 1)}
                            disabled={years === 1}
                            className="text-2xl px-4 py-2 text-[#0EAD98]">
                            <img src={minusIcon} alt="Decrease Years" className="w-8 h-8" />
                        </button>
                        <span className="font-manrope font-bold text-2xl text-[#0EAD98]">{years} Year</span>
                        <button
                            onClick={() => setYears(years + 1)}
                            disabled={years === 5} // Disable button if 5 years is selected
                            className="text-2xl px-4 py-2 text-white">
                            <img src={plusIcon} alt="Increase Years" className="w-8 h-8" />
                        </button>
                    </div>

                    {/* Pick by Date */}
                    <div className=" text-sm mb-4 text-center">
                        <span className='text-white font-manrope'>{years} year Registration.</span> <span className='text-[#0EAD98] font-manrope'>Pick by Date</span>
                    </div>

                    <div className="w-[640.84px] ml-4 h-[40.54px] flex justify-between items-center mb-4">
                        {/* Left Side: 10.9 Gwei */}
                        <span className="text-base flex items-center text-white font-manrope">
                            <img src={gweiIcon} alt="Gwei" className="mr-2" />
                            10.9 Gwei
                        </span>
                        {/* Right Side: Two Boxes */}
                        <div className="flex gap-2">
                            <div className="w-[60.59px] h-[35.52px] p-[3.76px_13.79px] rounded-[0px_0px_0px_0px] bg-[#0EAD98] text-black flex items-center justify-center font-manrope">
                                ETH
                            </div>
                            <div className="w-[60.59px] h-[35.52px] p-[3.76px_13.79px] rounded-[0px_0px_0px_0px] bg-[#292D32] text-white flex items-center justify-center font-manrope">
                                USD
                            </div>
                        </div>
                    </div>

                    {/* Additional Information Box */}
                    <div className="w-[640.84px] h-[106.19px] ml-4 rounded-[3.76px_0px_0px_0px] border border-[#A8A4A5] bg-[#292D32] mb-4 p-4 flex flex-col justify-between">
                        <div className="flex justify-between text-[#A8A4A5] font-manrope">
                            <span>{years} Year Registration</span>
                            <span>{priceMapping[years].toFixed(4)} ETH</span>
                        </div>
                        <div className="flex justify-between mt-1 text-[#A8A4A5] font-manrope ">
                            <span>Est. Network Fee</span>
                            <span>{priceMapping[years].toFixed(4)} ETH</span>
                        </div>
                        <div className="flex justify-between mb-2 mt-2 text-white font-bold font-manrope">
                            <span>Estimated Total</span>
                            <span>{(2 * priceMapping[years]).toFixed(4)} ETH</span>
                        </div>
                    </div>

                    {/* Pay Now Button */}
                    <button
                        onClick={handlePayClick}
                        className="w-[640.84px] h-[64.67px] bg-[#0EAD98] border border-[#0000001A] shadow-[0px_3.67px_0px_#0000000B] rounded-lg text-black font-manrope font-bold text-lg">
                        <span className="text-base flex justify-center text-black text-xl font-manrope font-bold">
                            Pay Now
                            <img src={arrowrightIcon} alt="arrow-right" className="ml-[20px]" />
                        </span>
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default RegisterDomain;

import React, { useState, useEffect } from 'react';
import { useWallet } from '../WalletContext'; // Make sure this context is correctly set up
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import chakraIcon from '../assets/chakra.png';
import UnionIcon from '../assets/union.png';
import InterIcon from '../assets/inter.png';
import headIcon from '../assets/head.png';
import { getDomainsByAddress, getDomainExpiryDate } from '../contracts/contract.ts';

const Mynames = () => {
    const { walletAddress } = useWallet(); // Get the wallet address from context
    const [domains, setDomains] = useState([]); // State to store the domains and their expiry dates
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchDomainsAndExpiryDates() {
            if (!walletAddress) {
                setLoading(false);
                return; // If no wallet address, exit early
            }

            console.log("Fetching domains for address:", walletAddress); // Log the address

            try {
                const userDomains = await getDomainsByAddress(walletAddress); // Fetch the user's domains

                // Check if userDomains is null or undefined
                if (!userDomains) {
                    console.error("No domains found for this address.");
                    setDomains([]);
                    return;
                }

                const domainData = await Promise.all(userDomains.map(async (domain) => {
                    const expiryDate = await getDomainExpiryDate(domain); // Fetch expiry date for each domain
                    return { domain, expiryDate }; // Return an object containing domain and its expiry date
                }));
                setDomains(domainData);
            } catch (error) {
                console.error("Error fetching domains or expiry dates:", error);
                setDomains([]); // Set domains to an empty array on error
            } finally {
                setLoading(false);
            }
        }
        fetchDomainsAndExpiryDates();
    }, [walletAddress]); // Add walletAddress as a dependency

    return (
        <div className="bg-[#0C0C0C] min-h-screen flex flex-col">
            <Navbar />
            <div className="flex flex-col items-center mt-8 justify-center h-screen font-manrope relative">
                <div className="w-[650px] h-[550px] p-8 rounded-xl border border-[#0C0C0C] bg-[#0C0C0C] shadow-lg">
                    {/* Search and Filter Section */}
                    <div className="flex justify-between items-center mb-6">
                        {/* Filter Dropdown */}
                        <div className="flex items-center gap-4">
                            <select className="bg-[#292D32] border border-gray-600 text-white px-4 py-2 rounded-md">
                                <option>Expiry date</option>
                                {/* Add more filter options as needed */}
                            </select>
                            {/* Up Arrow Button */}
                            <button className="bg-[#292D32] border border-gray-600 text-white p-3 rounded-md flex items-center justify-center">
                                <img src={InterIcon} alt="Up Arrow" className="h-3 w-3" />
                            </button>
                            {/* Down Arrow Button */}
                            <button className="bg-[#292D32] border border-gray-600 text-white p-3 rounded-md flex items-center justify-center">
                                <img src={UnionIcon} alt="Down Arrow" className="h-3 w-3" />
                            </button>
                        </div>

                        {/* Search Bar */}
                        <div className="relative w-[200px]">
                            <input
                                type="text"
                                placeholder="Search"
                                className="bg-[#292D32] border border-gray-600  text-white text-left w-[200px] px-2 py-2 rounded-sm"
                            />
                            <button className="absolute right-2 top-2 text-white">🔍</button>
                        </div>
                    </div>

                    <hr className="border-t border-gray-600 w-full mb-6" />

                    {/* Name List */}
                    <div className="space-y-8">
                        {loading ? (
                            <div className="text-white">Loading domains...</div>
                        ) : (
                            <div className="space-y-8">
                                {domains.length === 0 ? (
                                    <div className="text-white">No domains found.</div>
                                ) : (
                                    domains.map(({ domain, expiryDate }, index) => (
                                        <div key={index} className="flex items-center h-20 border border-gray-600 justify-between bg-[#292D32] text-white p-6 rounded-lg">
                                            <div className="flex items-center gap-6">
                                                <div className="bg-[#ffff] p-2 rounded-sm flex items-center justify-center">
                                                    <img src={chakraIcon} alt="Chakra icon" className="h-8 w-8" />
                                                </div>
                                                <div className="font-manrope text-left">
                                                    <p className="font-bold text-2xl">{domain}</p>
                                                    <p className="text-gray-400 text-sm">Expires on {new Date(expiryDate * 1000).toLocaleDateString()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}

                        {/* Info Box */}
                        <div className="mt-16 bg-[#ffff] text-white p-6 h-[130px] rounded-lg flex items-start">
                            <img src={headIcon} alt="Head icon" className="h-18 w-18 mt-4" />
                            <div className="ml-4">
                                <p className="font-bold text-left px-2 text-xl text-black">Some names may not appear</p>
                                <p className="text-[11px] text-left px-2 text-gray-400">Offchain names do not currently appear in this list. You can still view them by searching</p>
                                <p className="text-[11px] text-left px-2 text-gray-400">for them directly. Click to learn more.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Mynames;
